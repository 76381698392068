import { ReactNode } from 'react';
import './MainContent.scss';

interface MainContentProps {
    children: ReactNode; // Contenido interno
    background?: string; // URL del fondo (opcional)
  }

export const MainContent = ({children,background}:MainContentProps) => {
    return (
        <div className='main' style={{
            backgroundImage: background ? `url(${background})` : undefined,
            backgroundSize: 'cover', // Asegura que la imagen cubra todo el contenedor
            backgroundPosition: 'center', // Centra la imagen
            backgroundRepeat: 'no-repeat', // Evita repeticiones de la imagen
          }}>
            {children}
        </div>
    )
}