import './header.scss';
import { FaFacebookF } from 'react-icons/fa6';
import { FaInstagram } from 'react-icons/fa6';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaPhone } from 'react-icons/fa6';
import { FaWhatsapp } from 'react-icons/fa6';
import { FaXTwitter } from 'react-icons/fa6';
import { HeaderButton } from '../header-button/Header-button';
import {
  HeaderDropdownButton,
  HeaderDropdownButtonProps,
} from '../header-dropdown-button/Header-dropdown-button';
import { Link } from 'react-router-dom';
import { HeaderMobile } from '../header-mobile/Header-mobile';

export const Header = () => {
  const urls: Record<string, string> = {
    facebook: 'https://web.facebook.com/aSERseguridad/?_rdc=1&_rdr',
    linkedin:
      'https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Faserseguridadsas%2Fabout%2F',
    whatsapp:
      'https://api.whatsapp.com/send?phone=5717171621&text=Hola%2C%20me%20gustaria%20saber%20mas%20de%20las%20soluciones%20en%20Seguridad%20y%20Salud%20en%20el%20Trabajo.',
    instagram: 'https://www.instagram.com/aserseguridad/',
    twitter: 'https://x.com/aSERseguridad',
  };

  const socialButtons: { name: string; icon: JSX.Element }[] = [
    { name: 'facebook', icon: <FaFacebookF /> },
    { name: 'linkedin', icon: <FaLinkedinIn /> },
    { name: 'whatsapp', icon: <FaWhatsapp /> },
    { name: 'instagram', icon: <FaInstagram /> },
    { name: 'twitter', icon: <FaXTwitter /> },
  ];

  const headerDropdownButtons: HeaderDropdownButtonProps[] = [
    {
      label: 'Línea de Servicios',
      options: [
        { label: 'Ser Pyme SST', route: '/ser-pyme-sst' },
        { label: 'Outsourcing SST', route: '/outsourcing-sst' },
        { label: 'Proveedores SST', route: '/proveedores-sst' },
        // { label: 'Convenciones SST', route: '/convenciones-sst' },
      ],
    },
    {
      label: 'Comunidad',
      options: [
        { label: 'Blog', route: 'https://blog.grupogeos.co/' },
        // { label: 'Casos de Éxitos', route: '/casos-exito' },
        {
          label: 'Base de Conocimientos',
          route: 'https://conocimiento.grupogeos.co/',
        },
      ],
    },
  ];

  return (
    <div className="header-container">
      <div className="header">
        <div className="header__contact-us">
          <div className="header__contact-us__content">
            <div className="header__contact-us__content__number">
              <FaPhone className="header__contact-us__content__number__icon" />
              <span className="header__contact-us__content__number__text">
                (601) 717-1621 | +57 1 7171621
              </span>
            </div>
            <div className="header__contact-us__content__social">
              {socialButtons.map((el: { name: string; icon: JSX.Element }) => (
                <Link
                  key={el.name}
                  to={urls[el.name]}
                  className={`header__contact-us__content__social__icon header__contact-us__content__social__icon__${el.name}`}
                  tabIndex={0}
                >
                  {el.icon}
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="header__nav-bar">
          <div className="header__nav-bar__content">
            <Link to="/" className="header__nav-bar__content__logo">
              <div className="header__nav-bar__content__logo__image">
                <img
                  className="header__nav-bar__content__logo__image__img"
                  src="https://aserseguridad.co/assets/images/nav/logo-aser.png"
                  alt="Logo"
                />
              </div>
            </Link>
            <div className="header__nav-bar__content__buttons">
              <HeaderButton label="Inicio" route="/" />
              <HeaderButton label="Quiénes Somos" route="/quienes-somos" />

              {headerDropdownButtons.map(
                (el: HeaderDropdownButtonProps, index: number) => (
                  <HeaderDropdownButton
                    key={`${el.label}-${index}`}
                    label={el.label}
                    options={el.options}
                  />
                )
              )}
            </div>
          </div>
        </div>

        <div className="header__mobile">
          <HeaderMobile />
        </div>
      </div>

      <div className="header-support"></div>
    </div>
  );
};
