import React, { useState, useEffect } from 'react';
import './customer-carousel.scss';
import { items } from './customer-carousel-data';
import { TitleSection } from '../../shared/title-section/Title-section';

const CustomerCarousel = () => {
  const extendedItems = [...items, items[0], items[1], items[2], items[3]];
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex: number) => {
        if (prevIndex >= items.length) {
          return backToFirst();
        }
        return prevIndex + 1;
      });
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  const backToFirst = (): number => {
    setIsTransitioning(false);

    setTimeout(() => {
      setIsTransitioning(true);
      setCurrentIndex(1);
    }, 1);
    return 0;
  };

  useEffect(() => {
    const carouselInner: HTMLElement =
      document.querySelector('.carousel__inner');
    if (carouselInner) {
      carouselInner.style.setProperty('--current-index', `${currentIndex}`);
    }
  }, [currentIndex]);

  return (
    <div className="carousel">
      <TitleSection title="Clientes" description=""></TitleSection>
      <div className="carousel__container">
        <div
          className="carousel__inner"
          style={{
            transition: isTransitioning ? 'transform 0.5s ease' : 'none',
          }}
        >
          {extendedItems.map((item, index) => (
            <div key={index} className="carousel__item">
              <img src={item.image} alt={item.alt} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CustomerCarousel;
