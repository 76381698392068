import React from 'react';
import './header-button.scss';
import { useNavigate, useLocation } from 'react-router-dom';

interface HeaderButtonProps {
  label: string;
  route: string;
}

export const HeaderButton: React.FC<HeaderButtonProps> = ({ label, route }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const click = (route: string) => {
    navigate(route);
  };

  return (
    <button
      onClick={() => click(route)}
      className={`header-button ${location.pathname === route ? 'header-button__active' : ''}`}
    >
      {label}
    </button>
  );
};
