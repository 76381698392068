import './proveedores-sst-modal.scss';
import { FaTimes } from 'react-icons/fa';
import {
  pastelColors,
  ProveedoresSstProduct,
} from '../../../src/pages/proveedores-sst/proveedores-sst-data';
import { PiHexagonFill } from 'react-icons/pi';
import React, { useEffect, useState } from 'react';

interface ProveedoresSstModalProps {
  isVisible: boolean;
  onClose: () => void;
  card: ProveedoresSstProduct;
}

export const ProveedoresSstModal: React.FC<ProveedoresSstModalProps> = ({
  isVisible,
  onClose,
  card,
}) => {
  const [isAnimating, setIsAnimating] = useState(isVisible);

  useEffect(() => {
    if (isVisible) {
      setIsAnimating(true);
      document.body.style.overflow = 'hidden';
    } else {
      setTimeout(() => {
        setIsAnimating(false);
        document.body.style.overflow = 'auto';
      }, 300); // Tiempo de la animación de salida
    }
  }, [isVisible]);

  if (!isAnimating) return null;

  return (
    <div
      className={`proveedores-sst-modal__overlay ${isVisible ? 'show' : ''}`}
      onClick={onClose}
    >
      <div
        className={`proveedores-sst-modal__content ${isVisible ? 'enter' : 'exit'}`}
        onClick={(e) => e.stopPropagation()}
      >
        {card.image && (
          <div className="proveedores-sst-modal__content__image">
            <img
              alt={`${card.title}-image`}
              className="proveedores-sst-modal__content__image__img"
              src={card.image}
            />
          </div>
        )}
        <div className="proveedores-sst-modal__content__text">
          <div className="proveedores-sst-modal__content__text__title">
            <span className="proveedores-sst-modal__content__text__title__text">
              {card.title}
            </span>
          </div>

          <div className="proveedores-sst-modal__content__text__content">
            {card.content.map((text: string, index: number) => (
              <div
                className="proveedores-sst-modal__content__text__content__line"
                style={{ color: pastelColors[index % pastelColors.length] }}
                key={index}
              >
                <PiHexagonFill />
                <div className="proveedores-sst-modal__content__text__content__line__text">
                  {text}
                </div>
              </div>
            ))}
          </div>
        </div>

        <button className="proveedores-sst-modal__close" onClick={onClose}>
          <FaTimes />
        </button>
      </div>
    </div>
  );
};
