import { FaCalendarAlt } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa6';
import { FaInstagram } from 'react-icons/fa6';
import { FaLinkedinIn } from 'react-icons/fa';
import { FaWhatsapp } from 'react-icons/fa6';
import { FaXTwitter } from 'react-icons/fa6';

export interface FooterSection {
  title: string;
  items: FooterSectionItem[];
}

interface FooterSectionItem {
  label: string;
  icon?: JSX.Element;
  socialIcon?: JSX.Element;
  url: string;
}

export const data: FooterSection[] = [
  {
    title: 'Cosulta nuestra linea de servicios',
    items: [
      {
        label: 'SerPyme SST',
        url: '/ser-pyme-sst',
      },
      {
        label: 'Outsourcing SST',
        url: '/outsourcing-sst',
      },
      {
        label: 'Proveedor SST',
        url: '/proveedores-sst',
      },
      // {
      //   label: 'Convenciones SST',
      //   url: '/convenciones-sst',
      // },
    ],
  },
  {
    title: 'Contacta con oportunidades de venta',
    items: [
      {
        label: 'Contacta con oportunidades de venta',
        icon: <FaCalendarAlt />,
        url: 'https://comercial.grupogeos.co/meetings/manager-cmo/reunion-con-charly',
      },
    ],
  },
  {
    title: 'Nuestra Comunidad',
    items: [
      { label: 'Blog', url: 'https://blog.grupogeos.co/' },
      //   { label: 'Casos de éxito', url: '/casos-exito' },
      {
        label: 'Base de conocimiento',
        url: 'https://conocimiento.grupogeos.co/',
      },
    ],
  },
  {
    title: 'Conoce más de nosotros',
    items: [
      {
        label: 'facebook',
        socialIcon: <FaFacebookF />,
        url: 'https://web.facebook.com/aSERseguridad/?_rdc=1&_rdr',
      },
      {
        label: 'linkedin',
        socialIcon: <FaLinkedinIn />,
        url: 'https://www.linkedin.com/uas/login?session_redirect=https%3A%2F%2Fwww.linkedin.com%2Fcompany%2Faserseguridadsas%2Fabout%2F',
      },
      {
        label: 'whatsapp',
        socialIcon: <FaWhatsapp />,
        url: 'https://api.whatsapp.com/send?phone=5717171621&text=Hola%2C%20me%20gustaria%20saber%20mas%20de%20las%20soluciones%20en%20Seguridad%20y%20Salud%20en%20el%20Trabajo.',
      },
      {
        label: 'instagram',
        socialIcon: <FaInstagram />,
        url: 'https://www.instagram.com/aserseguridad/',
      },
      {
        label: 'twitter',
        socialIcon: <FaXTwitter />,
        url: 'https://x.com/aSERseguridad',
      },
    ],
  },
];
