import { MainContent } from 'src/shared/mainContent/MainContent';
import charlyImg from 'src/assets/CEO-CMO-Charly.png';
import derlyImg from 'src/assets/CFO-COO-Derly.png';
import aboutUs from 'src/assets/Quienes-somos.jpg';
import people from 'src/assets/People.png';
import cliente from 'src/assets/cliente.webp';
import experiencia from 'src/assets/experiencia.webp';
import reconocimiento from 'src/assets/reconocimientos.webp';
import '../../styles/animations.scss';
import './aboutUs.scss';
import {
  Card,
  CardContent,
  CardDescription,
  // CardHeader,
  CardTitle,
} from 'src/components/card/Card';
import compromiso from '../../assets/compromiso.webp';
import competitividad from '../../assets/competitividad.webp';
import clientes from '../../assets/clientes.webp';
import responsabilidad from '../../assets/responsabilidad laboral.webp';
import ValuesTabs from '../../components/values-tabs/ValueTabs';


const AboutUs = () => {
  const admins = [
    {
      name: 'Charly Oviedo Gomez',
      pic: charlyImg,
      job: 'CMO / Director Comercial y Marca: Gestión de ventas y fidelización de clientes',
    },
    {
      name: 'Derly Sánchez Martínez',
      pic: derlyImg,
      job: 'CEO / Directora General Planeación estratégica y crecimiento de la organización',
    },
  ];

  const cards = [
    {
      img: cliente,
      title: '+122 clientes activos',
      description:
        'Nuestros clientes son respaldados por nuestra marca, garantizandoles tranquilidad y confianza',
    },
    {
      img: experiencia,
      title: '12 años de experiencia',
      description:
        'Gestionar la seguridad y salud en el trabajo en nuestros clientes es ofrecer una experiencia excepcional en servicio.',
    },
    {
      img: reconocimiento,
      title: '3 reconocimientos',
      description:
        'Ser galardonados por nuestros aliados y empresas en SST refuerza nuestra responsabilidad de estar siempre al servicio de nuestros clientes.',
    },
  ];

  const values = [
    {
      title: 'Responsabilidad laboral',
      description:
        'Cuanto mayor sea el nivel de responsabilidad de nuestros empleados, mayor será su posición en nuestra empresa, nuestro cliente y su familia',
      img: {responsabilidad},
    },
    {
      title: 'Enfoque en los Clientes',
      description:
        'Dar valor a nuestros clientes con la satisfacción de nuestros servicios a precios competitivos evaluando constantemente la percepción del mercado',
      img: {clientes},
    },
    {
      title: 'Competitividad e Innovación',
      description:
        'Nuevas ideas definidas internamente en una cultura abierta de ambiente participativo dando escalabilidad a nuestros clientes y trabajadores',
      img: {competitividad},
    },
    {
      title: 'Compromiso con la Calidad, SST y Ambiente',
      description:
        'Compromiso con la seguridad y salud en el trabajo, ambiente y calidad generando una cultura integral y la sostenibilidad de nuestra organización.',
      img: {compromiso},
    },
  ];

  return (
    <>
      <MainContent background={aboutUs}>
        <h1 className="text-4xl font-bold text-gray-800 text-center mb-8 pt-4 fadeInUp">
          ¿Quiénes somos?
        </h1>
        <div className="flex items-center md:items-start">
          <div className="about-us">
            <h1 className="text-4xl md:text-7xl font-bold text-gray-800 mb-4 md:mb-8 pt-4 lg:w-1/2 w-full text-center md:text-left px-4 md:pl-6 fadeInUp">
              Nos enfocamos en Respaldar a todas las <b>PYMES</b>
            </h1>
            <p className="px-4 md:pl-6 md:w-full lg:w-3/5 xl:w-1/2 text-lg md:text-3xl font-light text-center md:text-left fadeInLeft z-10 position-relative">
              Con la implementación de su SG-SST; Generando aumento en su
              productividad empresarial y reconocimiento de marca.
            </p>
          </div>
          <div className="people fadeInRight">
            <img src={people} alt="" />
          </div>
        </div>
      </MainContent>
      <>
        <div className="background pt-6">
          <h2 className="text-5xl font-extrabold text-gray-800 text-center text-slate-50">
            Nuestra Misión
          </h2>

          <p className="px-8 text-slate-50 text-center font-light mx-auto w-1/2">
            En Aserseguridad, nos dedicamos a impulsar la seguridad y la salud
            en el trabajo, creando entornos laborales seguros que fomentan el
            desarrollo social, industrial y económico, respaldados por un equipo
            altamente calificado y comprometido.
          </p>

          <div className="card-container">
            {cards.map(({ img, title, description }) => (
              <Card key={title} className="single-card">
                <img src={img} alt={title} />
                <CardTitle className="single-card__title">{title}</CardTitle>
                <CardContent>
                  <CardDescription className="CardDescription">
                    {description}
                  </CardDescription>
                </CardContent>
              </Card>
            ))}
          </div>

          <h2 className="text-5xl font-extrabold text-gray-800 text-center text-slate-50">
            Conoce a nuestro equipo administrativo
          </h2>
          <div className="flex justify-center pt-4 pb-6 max-md:flex-col">
            {admins.map(({ name, pic, job }) => (
              <div className="admins-card">
                <div className="admins-card__container">
                  <img src={pic} alt={name} />
                  <div className="admin-name">
                    <span className="font-bold">{name}</span>
                    <span className="text-xs">{job}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </>
      <div className="our-values">
        <div className='pt-6 pb-6 my-auto'>
          <h1>Nuestros Valores</h1>

          <div>
            <ValuesTabs/>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
