import agroinsumosSalamanca from 'src/assets/brand-image/Agroinsumos-Salamanca.jpg';
import agropinos from 'src/assets/brand-image/Agropinos.jpg';
import premiunBeer from 'src/assets/brand-image/Premium-Beer.png';
import intercona from 'src/assets/brand-image/Intercona.jpg';
import almacenElArquitecto from 'src/assets/brand-image/Almacen-el-arquitecto.webp';
import innovarIluminacionLtda from 'src/assets/brand-image/Innovar-Iluminación-Ltda.jpg';
import legnotec from 'src/assets/brand-image/Legnotec.jpg';
import pavoConstruccionesSAS from 'src/assets/brand-image/Pavo-Construcciones-SAS.png';
import representacionesARCADA from 'src/assets/brand-image/Representaciones-ARCADA.webp';
import bancoAgrarioDeColombia from 'src/assets/brand-image/Banco-Agrario-de-Colombia.jpg';
import bancoldex from 'src/assets/brand-image/Bancoldex.png';
import solucionesEnRED from 'src/assets/brand-image/Soluciones-en-RED.png';
import viajeroHostels from 'src/assets/brand-image/Viajero-Hostels.png';
import ecopositiva from 'src/assets/brand-image/Ecopositiva.png';
import expresoAndinoDeCarga from 'src/assets/brand-image/Expreso-Andino-de-Carga.png';
import jVParkingSAS from 'src/assets/brand-image/JV-Parking-SAS.jpg';
import operadorAndinoDeCarga from 'src/assets/brand-image/Operador-Andino-de-Carga.png';
import accepetrol from 'src/assets/brand-image/Accepetrol.png';
import artePop from 'src/assets/brand-image/Arte-Pop.png';
import cofrecol from 'src/assets/brand-image/Cofrecol.png';
import publisos from 'src/assets/brand-image/Publisos.png';
import arlSura from 'src/assets/brand-image/ARL-SURA.webp';
import grupoKycConsultoresSas from 'src/assets/brand-image/Grupo-KYC-Consultores-SAS.jpg';
import valueAndRisk from 'src/assets/brand-image/Value-and-Risk.png';
import heliosTechologyInnovation from 'src/assets/brand-image/Helios-Tecnhology-Innovation.png';
import itsecSAS from 'src/assets/brand-image/Itsec-SAS.jpeg';
import lgElectronicsColombia from 'src/assets/brand-image/LG-Electronics-Colombia.jpg';
import novasoft from 'src/assets/brand-image/Novasoft.png';
import cm from 'src/assets/brand-image/CM.png';
import apoyoDeLaboresTemporales from 'src/assets/brand-image/Apoyo-de-Labores-Temporales.png';
import temporalCoactiva from 'src/assets/brand-image/Temporal-Coactiva.jpg';
import grupoAsthecFormaTuCuerpo from 'src/assets/brand-image/Grupo-Asthec-Forma-tu-cuerpo.webp';
import pettacci from 'src/assets/brand-image/Pettacci.jpg';
import seguridadPrivadaKgb from 'src/assets/brand-image/Seguridad-Privada-KGB.jpg';

export interface CustomerCarouselItem {
  image: string;
  alt: string;
}

export const items: CustomerCarouselItem[] = [
  {
    image: agroinsumosSalamanca,
    alt: 'Agroinsumos Salamanca',
  },
  {
    image: agropinos,
    alt: 'Agropinos',
  },
  {
    image: premiunBeer,
    alt: 'Premiun-Beer',
  },
  {
    image: intercona,
    alt: 'Intercona',
  },
  {
    image: almacenElArquitecto,
    alt: 'Almacen-el-arquitecto',
  },
  {
    image: innovarIluminacionLtda,
    alt: 'Innovar-Iluminacion-Ltda',
  },
  {
    image: legnotec,
    alt: 'Legnotec',
  },
  {
    image: pavoConstruccionesSAS,
    alt: 'Pavo-Construcciones-SAS',
  },
  {
    image: representacionesARCADA,
    alt: 'Representaciones-ARCADA',
  },
  {
    image: bancoAgrarioDeColombia,
    alt: 'Banco-Agrario-de-Colombia',
  },
  {
    image: bancoldex,
    alt: 'Bancoldex',
  },
  {
    image: solucionesEnRED,
    alt: 'Soluciones-en-RED',
  },
  {
    image: viajeroHostels,
    alt: 'Viajero-Hostels',
  },
  {
    image: ecopositiva,
    alt: 'Ecopositiva',
  },
  {
    image: expresoAndinoDeCarga,
    alt: 'Expreso-Andino-de-Carga',
  },
  {
    image: jVParkingSAS,
    alt: 'JV-Parking-SAS',
  },
  {
    image: operadorAndinoDeCarga,
    alt: 'Operador-Andino-de-Carga',
  },
  {
    image: accepetrol,
    alt: 'Accepetrol',
  },
  {
    image: artePop,
    alt: 'Arte-Pop',
  },
  {
    image: cofrecol,
    alt: 'Cofrecol',
  },
  {
    image: publisos,
    alt: 'Publisos',
  },
  {
    image: arlSura,
    alt: 'ARL-SURA',
  },
  {
    image: grupoKycConsultoresSas,
    alt: 'Grupo-KYC-Consultores-SAS',
  },
  {
    image: valueAndRisk,
    alt: 'Value-and-Risk',
  },
  {
    image: heliosTechologyInnovation,
    alt: 'Helios-Techology-Innovation',
  },
  {
    image: itsecSAS,
    alt: 'Itsec-SAS',
  },
  {
    image: lgElectronicsColombia,
    alt: 'LG-Electronics-Colombia',
  },
  {
    image: novasoft,
    alt: 'Novasoft',
  },
  {
    image: cm,
    alt: 'CM',
  },
  {
    image: apoyoDeLaboresTemporales,
    alt: 'Apoyo-de-Labores-Temporales',
  },
  {
    image: temporalCoactiva,
    alt: 'Temporal-Coactiva',
  },
  {
    image: grupoAsthecFormaTuCuerpo,
    alt: 'Grupo-Asthec-Forma-tu-cuerpo',
  },
  {
    image: pettacci,
    alt: 'Pettacci',
  },
  {
    image: seguridadPrivadaKgb,
    alt: 'Seguridad-Privada-KGB',
  },
];
