import { BannerProps } from '@/src/shared/banner/Banner';
import logo from 'src/assets/images/portrait-happy-attractive-man-woman-smart-clothes.png';

export interface InfoListItem {
  number: string;
  texts: string[];
  primaryColor: string;
  secondaryColor: string;
}

export const items: InfoListItem[] = [
  {
    number: '1',
    texts: [
      'Eduación.',
      'Capacitación.',
      'Diseño, administración y ejecución del sistema de gestión de la seguridad y salud en el trabajo.',
    ],
    primaryColor: '#661f28',
    secondaryColor: '#972128',
  },
  {
    number: '2',
    texts: [
      'Ingeniería en seguridad y salud en el trabajo o su equivalente.',
      'Higiene y seguridad industrial, cuando lo acredite en su formación de posgrado.',
      'Investigación en área técnica.',
      'Investigación del accidente de trabajo de acuerdo con su competencia según formación académica.',
      'Educación.',
      'Capacitación.',
      'Diseño, administración y ejecución del sistema de gestión de la seguridad y salud en el trabajo.',
    ],
    primaryColor: '#444447',
    secondaryColor: '#5c5b5e',
  },
  {
    number: '3',
    texts: [
      'Profesión en seguridad y salud en el trabajo.',
      'Investigación en área técnica.',
      'Investigación del accidente de acuerdo con su competencia según formación académica.',
      'Educación.',
      'Capacitación.',
      'Diseño, administración y ejecución del sistema de gestión de la seguridad y salud en el trabajo.',
    ],
    primaryColor: '#6d6d6f',
    secondaryColor: '#7d7c7f',
  },
  {
    number: '4',
    texts: [
      'Profesión en seguridad y salud en el trabajo o su equivalente.',
      'Higiene industrial, cuando lo acredite en su formación.',
      'Seguridad industrial, cuando lo acredite en su formación.',
      'Investigación de accidente de trabajo.',
      'Educación.',
      'Capacitación.',
      'Diseño, administración y ejecución del sistema de gestión de la seguridad y salud en el trabajo.',
    ],
    primaryColor: '#ca7731',
    secondaryColor: '#d08b2b',
  },
];

export const banner: BannerProps = {
  title: 'Outsourcing SST',
  description: `Con nosotros cuentas con profesionales expertos en SST avalados, quienes te representarán y acompañarán en tu empresa, para ejecutar y mantener tu SG-SST.

Pregúntanos por la modalidad Outsourcing SST.`,
  image:
    'https://images.unsplash.com/photo-1556761175-5973dc0f32e7?q=80&w=1932&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  logo,
};
