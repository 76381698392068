import React, { useState, useRef, useEffect } from 'react';
import './header-dropdown-button.scss';
import { FaChevronDown } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';

export interface HeaderDropdownButtonProps {
  label: string;
  options: HeaderDropdownButtonOptions[];
}

interface HeaderDropdownButtonOptions {
  label: string;
  route: string;
}

export const HeaderDropdownButton: React.FC<HeaderDropdownButtonProps> = ({
  label,
  options,
}) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState<number | null>(null);
  const optionRefs = useRef<(HTMLAnchorElement | null)[]>([]);
  const dropdownRef = useRef<HTMLDivElement | null>(null);

  const toggleDropdown = () => {
    setIsActive(!isActive);

    if (!isActive) {
      setTimeout(() => {
        setFocusedIndex(0);
        optionRefs.current[0]?.focus();
      }, 0);
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLButtonElement | HTMLAnchorElement>
  ) => {
    if (!isActive || focusedIndex === null) return;

    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();
        const nextIndex = (focusedIndex + 1) % options.length;
        setFocusedIndex(nextIndex);
        optionRefs.current[nextIndex]?.focus();
        break;

      case 'ArrowUp':
        event.preventDefault();
        const prevIndex = (focusedIndex - 1 + options.length) % options.length;
        setFocusedIndex(prevIndex);
        optionRefs.current[prevIndex]?.focus();
        break;

      case 'Tab':
        setIsActive(false);
        break;

      default:
        break;
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div
      ref={dropdownRef}
      className={`header-dropdown-button
      ${isActive ? 'header-dropdown-button__expanded' : ''} 
      ${options.some((option) => option.route === location.pathname) ? 'header-dropdown-button__active' : ''}`}
    >
      <button
        className="header-dropdown-button__button"
        onClick={toggleDropdown}
        onKeyDown={handleKeyDown}
      >
        <span className="header-dropdown-button__button__label">{label}</span>
        <FaChevronDown className="header-dropdown-button__button__icon" />
      </button>

      <div className="header-dropdown-button__options">
        {options.map((el, index) => (
          <Link
            key={el.route}
            ref={(el) => (optionRefs.current[index] = el)}
            to={el.route}
            tabIndex={isActive ? 0 : -1}
            className="header-dropdown-button__options__option"
            onKeyDown={handleKeyDown}
          >
            {el.label}
          </Link>
        ))}
      </div>
    </div>
  );
};
