import './home.scss';
import logo from 'src/assets/images/rb_2149241256.png';
import HomeButton from '../../components/home-button/Home-button';
import '../../styles/animations.scss'

const Home = () => {
  return (
    <div className="home">
      <div className="home__content">
        <h1 className="home__content__title fadeInLeft md:text-6xl sm:text-sm">
          Tu <span className="home__content__title__tagged">respaldo</span> en
          riesgos laborales
        </h1>
        <span className="home__content__description fadeInLeftDelay sm:text-sm">
          Nos enfocamos en respaldar a todas las PYMES, en la intervención de
          Riesgos Laborales con la implementación de su SG-SST. <br /> Generando
          aumento en su productividad empresarial y reconocimiento de marca.
        </span>
        <div className="home__content__buttons fadeIn">
          <HomeButton
            label="Nuestros servicios"
            color="white"
            url="our-services"
          ></HomeButton>
          <HomeButton
            label="Contáctanos"
            color="red"
            url="contact-us"
          ></HomeButton>
        </div>
      </div>
      <div className="home__image fadeInRight">
        <img src={logo} alt="" className="home__image__img" />
      </div>
    </div>
  );
};

export default Home;
