import './title-section.scss';

export interface TitleSectionProps {
  title: string;
  description: string;
  align?: 'left' | 'center' | 'right';
}

export const TitleSection: React.FC<TitleSectionProps> = ({
  title,
  description,
  align = 'center',
}) => {
  return (
    <div className={`title-section title-section__${align}`}>
      <div className="title-section__title">
        <span className="title-section__title__text">{title}</span>
        <div className="title-section__title__line">
          <div className="title-section__title__line__color"></div>
        </div>
      </div>
      {description && (
        <span className="title-section__description">{description}</span>
      )}
    </div>
  );
};
