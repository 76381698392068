import { TitleSection } from 'src/shared/title-section/Title-section';
import ContactForm from 'src/shared/contactForm/ContactForm';

// Esquema de validación

const ContactUs = () => {
  return (
    <>
      <div id="contact-us" className="flex max-md:flex-col gap-4">
        <TitleSection
          title="Contáctanos"
          align="left"
          description={`Conoce nuestro modelo de suscripción para la implementación del SG-SST.`}
        ></TitleSection>
        <ContactForm/>
      </div>
    </>
  );
};

export default ContactUs;
