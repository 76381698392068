import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "src/components/card/Card";
import React from "react";
import { AiOutlineSafetyCertificate } from "react-icons/ai";
import { FaPeopleGroup } from "react-icons/fa6";
import { Button } from "src/shared/button/Button";
import './ourServices.scss';
import { useNavigate } from "react-router-dom";



const OurServices = () => {
  const navigate = useNavigate();
  const services = [
    {
      icon: <AiOutlineSafetyCertificate />,
      title: "Ser Pyme SST",
      description: "Respaldo garantizado para que te acredites en la implementación del SG-SST, bajo la modalidad de suscripción con un Precio cómodo mensual según el plan que elijas.",
      path:'ser-pyme-sst'
    },
    {
      icon: <FaPeopleGroup />,
      title: "Outsourcing SST",
      description: "Con nosotros cuentas con profesionales expertos en SST avalados, quienes te representarán y acompañarán en tu empresa, para ejecutar y mantener tu SG-SST.",
      path:'outsourcing-sst'
    },
    {
      icon: <AiOutlineSafetyCertificate />,
      title: "Proveedores SST",
      description: "aSERseguridad te apoya en la Ejecución de tu sistema de gestión en seguridad en el trabajo, selecciona en nuestra gama de productos en SST, y dale cumplimiento a tu plan de trabajo.",
      path:'proveedores-sst'
    },
  //   {
  //     icon: <AiOutlineSafetyCertificate />,
  //     title: "Convenciones SST",
  //     description: "Acreditación en la implementación del SG-SST",
  //   },
  ];


  return (
    <div className="px-6">
      <h1 className="text-4xl font-bold text-gray-800 text-center mb-8 pt-6">
        Nuestros Servicios
      </h1>
      <p className="text-center pb-8 text-lg font-light w-8/12 mx-auto">
        Brindarte soluciones es nuestro propósito, conoce a detalle cada una de nuestras líneas de servicio y selecciona la que más se ajuste a tus necesidades
      </p>
      <div className="cards-container pb-6">
        {services.map(({ icon, title, description,path }, index) => (
          <Card key={index} className="bg-white shadow-md rounded-lg p-4 flex flex-col justify-between card">
            <CardHeader className="flex items-center gap-2">
              <div className="circle-icon">
                <span className="text-2xl text-white">{icon}</span>
                </div>
              <CardTitle className="font-bold text-gray-800 text-center mb-8">{title}</CardTitle>
              <div className="divider"></div>
            </CardHeader>
            <CardContent>
              <CardDescription><p>{description}</p></CardDescription>
            </CardContent>
            <CardFooter>
              <p className="text-sm text-gray-500 mx-auto">
                <Button onClick={()=>navigate(path)}>Más Información</Button>
                </p>
            </CardFooter>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default OurServices;
