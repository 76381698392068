import React, { useState } from 'react';
import responsabilidad from '../../assets/compromiso.webp';
import clientes from '../../assets/clientes.webp';
import competitividad from '../../assets/competitividad.webp';
import compromiso from '../../assets/responsabilidad laboral.webp';
import './valueTabs.scss';

interface Value {
  title: string;
  description: string;
  img: string;
}

const values: Value[] = [
  {
    title: 'Responsabilidad laboral',
    description:
      'Cuanto mayor sea el nivel de responsabilidad de nuestros empleados, mayor será su posición en nuestra empresa, nuestro cliente y su familia.',
    img: responsabilidad,
  },
  {
    title: 'Enfoque en los Clientes',
    description:
      'Dar valor a nuestros clientes con la satisfacción de nuestros servicios a precios competitivos evaluando constantemente la percepción del mercado.',
    img: clientes,
  },
  {
    title: 'Competitividad e Innovación',
    description:
      'Nuevas ideas definidas internamente en una cultura abierta de ambiente participativo dando escalabilidad a nuestros clientes y trabajadores.',
    img: competitividad,
  },
  {
    title: 'Compromiso con la Calidad',
    description:
      'Compromiso con la seguridad y salud en el trabajo, ambiente y calidad generando una cultura integral y la sostenibilidad de nuestra organización.',
    img: compromiso,
  },
];

const ValuesTabs: React.FC = () => {
  const [selectedValue, setSelectedValue] = useState<Value>(values[0]);

  return (
    <div className="values-tabs">
      <div className="values-tabs__content flex-row-reverse">
        <div className="w-80 max-sm:w-auto">
          <img
            src={selectedValue.img}
            alt={selectedValue.title}
            className="values-tabs__icon fadeIn"
          />
        </div>
        <div>
          <div className="values-tabs__options">
            {values.map((value, index) => (
              <button
                key={index}
                onClick={() => setSelectedValue(value)}
                className={`values-tabs__option ${
                  selectedValue.title === value.title ? 'active' : ''
                }`}
              >
                {value.title}
              </button>
            ))}
          </div>
          <p className="values-tabs__description">
            {selectedValue.description}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ValuesTabs;
