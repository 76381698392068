import { FaMap } from 'react-icons/fa';
import { FaMapSigns } from 'react-icons/fa';
import { AiOutlineBank } from 'react-icons/ai';
import { IoIosWarning } from 'react-icons/io';
import { FaClipboardCheck } from 'react-icons/fa6';
import { TbHeartHandshake } from 'react-icons/tb';
import { FaFlag } from 'react-icons/fa6';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FaGraduationCap } from 'react-icons/fa6';
import { FaGears } from 'react-icons/fa6';
import automatizacionDigital from 'src/assets/product-image/automatizacion-digital-sst.jpg';
import sistemasDeGestion from 'src/assets/product-image/premium_photo-1681691911660-a40d4163ed9f.png';
import planLegal from 'src/assets/product-image/plan-legal.jpg';
import seguridadIndustrial from 'src/assets/product-image/seguridad-industrial.jpg';
import higieneIndustrial from 'src/assets/product-image/higiene-industral.jpg';
import medicinaPreventiva from 'src/assets/product-image/medicina-preventiva.jpg';
import planesDeEmergencia from 'src/assets/product-image/planes-de-emergencia.jpg';
import programaDePrevencion from 'src/assets/product-image/programas-prevencion.jpg';
import formacionYCapacitacion from 'src/assets/product-image/formacion.jpg';
import coaching from 'src/assets/product-image/coaching.jpg';

export interface ProveedoresSstProduct {
  title: string;
  content: string[];
  icon: JSX.Element;
  image: string;
}

export const pastelColors = [
  '#F7B7B7', // Rosa pastel
  '#FBD190', // Amarillo pastel
  '#A8E3C6', // Verde menta
  '#9CC7D9', // Azul claro
  '#B6A3E6', // Lila pastel
  '#F4AEB8', // Salmón pastel
  '#D7D786', // Amarillo suave
  '#FFBCC3', // Rosa claro
  '#FFE3B2', // Crema
  '#A5DDCF', // Turquesa suave
  '#A3CFF4', // Azul cielo
  '#F1BEAE', // Melocotón suave
  '#D7C3E5', // Lavanda suave
  '#FCC5B8', // Coral claro
];

export const products: ProveedoresSstProduct[] = [
  {
    title: 'Automatización digital en SST',
    icon: <FaMap />,
    content: [
      'Automatización digital con aplicativo Web para la administración, ejecución y control del sistema de gestión en seguridad y salud en el trabajo.',
    ],
    image: automatizacionDigital,
  },
  {
    title: 'Sistemas de gestión',
    icon: <FaMapSigns />,
    content: [
      'Auditoría Legal en Seguridad y Salud en el Trabajo (Decreto 1072 de 2015).',
      'Asesoría en el Cierre de No Conformidades a los Sistemas de Gestión.',
      'Auditorias externas para procesos certificacion en ISO, Calidad, Ambiente y Seguridad y Salud en el Trabajo.',
      'Implementación de los Sistemas de Gestión ISO 9001 – ISO 14001 – ISO 31000 – ISO 45001 – RUC “Procesos de certificación y acompañamiento externo.',
    ],
    image: sistemasDeGestion,
  },
  {
    title: 'Plan legal',
    icon: <AiOutlineBank />,
    content: [
      'Auditoría en los estándares mínimos según lineamientos MINISTERIO DE TRABAJO Res. 0312 de 2019.',
      'Asesoría en Responsabilidad Legal laboral basado en el Sistema General de Riesgos Laborales. “Matrices Legales',
      'Entrenamiento y estructuración de Comite Paritario en Seguridad y Salud en el Trabajo (COPASST).',
      'Diseño del Reglamento de Higiene y Seguridad Industrial.',
      'Diseño en la elaboración de la Política de Seguridad y Salud en el Trabajo.',
      'Diseño en el Procedimiento para Notificación e Investigación de Accidentes de Trabajo. Res. 1401 del 2007 “Metodologías de Investigación.',
      'Diseño de planes de trabajo de acuerdo al Sistema de Gestión de la Seguridad y Salud en el Trabajo.',
      'Diseño del método para evaluar responsabilidades en Seguridad y Salud en el trabajo según sea el cargo en la organización.',
      'Diseño de plataforma de indicadores del sistema de gestión de seguridad y salud en el trabajo.',
    ],
    image: planLegal,
  },
  {
    title: 'Seguridad industrial',
    icon: <IoIosWarning />,
    content: [
      'Elaboración de matrices de peligros. GTC 45, HAZOP, AMFE, WHAT IF. PHA.',
      'Ejecución de inspecciones de seguridad con indicadores de gestión. Modelo NTC 4114.',
      'Diseño de planes de trabajo para prevención en accidentes de trabajo. Bajo Res. 1401 del 2007.',
      'Asesoría en almacenamiento seguro y distribución de planta. Standar OSHA.',
      'Diseño de sistemas de almacenamiento y rotulado de sustancias químicas. NFPA 704, NTC 4435.',
      'Asesoría en normalización de actividades y estándares de seguridad industrial. Modelo NTC 4116.',
      'Diseño de matriz de elementos de protección personal. “Cargos o Proceso',
      'Investigación de accidentes de trabajo. MORTALES, GRAVES Según Res. 1401 del 2017, acompañamiento en auditoría por Inspectores de trabajo del Ministerio de Trabajo.',
      'Diseño de programa prevención de la accidentalidad basada en el comportamiento.',
      'Estudios de señalización en seguridad industrial.',
    ],
    image: seguridadIndustrial,
  },
  {
    title: 'Higiene industrial',
    icon: <FaClipboardCheck />,
    content: [
      'Análisis de las Mediciones ocupacionales y controles de fuentes bajo diseño de sistemas de ingeniería.',
      'Seguimiento a planes de acción para mejorar las condiciones higiénicas según resultados de las mediciones ocupacionales.',
      'Desarrollo de Mediciones Ocupacionales. “Ruido, Iluminación, Confort térmicos, Vibraciones y material particulado.',
    ],
    image: higieneIndustrial,
  },
  {
    title: 'Medicina preventiva del trabajo',
    icon: <TbHeartHandshake />,
    content: [
      'Análisis de factores de riesgos psicosociales bajo Res. 2646 del 2008.',
      'Desarrollo del programa de riesgo psicosocial.',
      'Conformación del comité de convivencia laboral.',
      'Aplicación de encuestas de baterías del Ministerio de Trabajo.',
      'Intervención a los factores de riesgo psicosocial potencial.',
      'Técnicas para el manejo del estrés.',
      'Diseño de programas de vigilancia epidemiológica para los riesgos higiénicos propensos a enfermedades laborales.',
      'Diseño de programa de prevención de lesiones osteomusculares.',
      'Inspecciones de puesto de trabajo con énfasis osteomuscular. APT.',
      'Análisis del ausentismo laboral.',
      'Diseño de profesiogramas.',
      'Diseño de procedimientos para exámenes médicos ocupacionales.',
    ],
    image: medicinaPreventiva,
  },
  {
    title: 'Planes de emergencia',
    icon: <FaFlag />,
    content: [
      'Diseño y aplicación de Sistema de Comando de Incidentes.',
      'Diseño de planes de prevención, preparación y atención de emergencias.',
      'Diseños de procedimientos operativos normalizados.',
      'Diseño de planes de atención de incidentes.',
      'Asesoría en identificación, análisis de amenazas y de vulnerabilidad.',
      'Conformación de la brigada de emergencias.',
      'Diseño de programas de formación a la brigada de emergencias.',
      'Formación brigadas de emergencias. BASICAS, INTERMEDIAS, AVANZADAS.',
      'Diseño de plan de emergencias químicas.',
      'Diseño en planes de contingencias.',
      'Estudios de necesidades de señalización de emergencias.',
      'Auditorías a planes de emergencias.',
      'Simulacros de emergencias.',
      'Control del riesgo ofídico.',
      'Asesoría en el manejo de hojas de seguridad (msds) y tarjetas de emergencia.',
    ],
    image: planesDeEmergencia,
  },
  {
    title: 'Programa de prevención y control de tareas críticas',
    icon: <IoDocumentTextOutline />,
    content: [
      'Diseño de programas de gestión para tareas de alto riesgo (trabajos en alturas, espacios confinados, trabajo en caliente y aseguramiento de energías peligrosas, excavaciones, izaje de cargas, riesgo público y maquinaria pesada).',
      'Diseño del modelo de permisos de trabajo según la actividad de alto riesgo.',
      'Análisis de tareas críticas y modelos de intervención.',
      'Diseño en el programa transporte seguro de valores.',
      'Diseño programa seguro en alturas (certificación trabajo seguro en alturas) e identificación de dispositivos de seguridad. Res. 1409 del 2012.',
      'Diseño de programa de seguridad vial. Res. 1565 del 2014.',
    ],
    image: programaDePrevencion,
  },
  {
    title: 'Formación y capacitación',
    icon: <FaGraduationCap />,
    content: [
      'Capacitación en seguridad industrial; Riesgos mecánicos, riesgos locativos, riesgos eléctricos, riesgos públicos, riesgo de tránsito y riesgo químico.',
      'Capacitación en higiene industrial; Riesgos biomecánicos, riesgos físicos y iesgos químicos.',
      'Capacitación en planes de emergencias; primeros auxilios, prácticas bomberibles, evacuación, búsqueda y rescate, sistema de comando incidentes.',
      'Seminarios en sistemas de gestión en seguridad y salud en el trabajo.',
      'Curso de auditoría en sistema de gestión de seguridad y salud en el trabajo.',
      'Formación en trabajo seguro en alturas según Res. 1409 del 2012.',
    ],
    image: formacionYCapacitacion,
  },
  {
    title: 'Coaching en SST',
    icon: <FaGears />,
    content: [
      'Prevenir accidentes por medio de la concienciación y el amor a la vida y la familia.',
      'Fomentar una cultura fuerte en seguridad en todas las actividades, haciendo que ésta una prioridad y posicionando la estrategia como un compromiso permanente, individual y común.',
      'Generar comportamientos consientes para des automatizar las actividades cotidianas y convertir cada una de éstas en estados presenciales del ser viviendo aquí y ahora.',
      'Influenciar los comportamientos a riesgo del grupo, mediante el ejemplo en todos los niveles y procesos de la organización.',
      'Fortalecer la consciencia en la identificación de peligros, riesgos y controles.',
      'Implementar manifestaciones visibles y evaluar las acciones coherentes del compromiso consciente para modificar el comportamiento riesgoso de las personas.',
      'Generar una conciencia consiente en todos los aspectos de la vida de cada participante “creando un mejor futuro aquí y ahora ”, a través de la metodología “MINDFULNESS” o conciencia plena ( AQUÍ Y AHORA).',
    ],
    image: coaching,
  },
];
