import React, { useEffect, useRef, useState } from 'react';
import { MainContent } from '../mainContent/MainContent';
import './banner.scss';

export interface BannerProps {
  title: string;
  description: string;
  image: string;
  logo: string;
}

export const Banner: React.FC<BannerProps> = ({
  title,
  description,
  image,
  logo,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLSpanElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  // Usamos useRef para evitar que breakpointWidth sea una dependencia directa de useEffect
  const breakpointWidthRef = useRef(0);

  useEffect(() => {
    const checkOverflow = () => {
      if (titleRef.current && containerRef.current) {
        const titleWidth = titleRef.current.clientWidth;
        const containerWidth = containerRef.current.clientWidth;
        const setValue: boolean = titleWidth > containerWidth;

        if (titleWidth > containerWidth) {
          setIsOverflowing(setValue);

          if (setValue && !breakpointWidthRef.current) {
            breakpointWidthRef.current = containerWidth;
          }
        }
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [title]);

  return (
    <MainContent>
      <div className="banner">
        <div ref={containerRef} className="banner__content">
          <span
            ref={titleRef}
            className={`banner__content__title ${
              isOverflowing ? 'banner__content__title__line-break' : ''
            } fadeInLeft`}
          >
            {title}
          </span>
          <span className="banner__content__description fadeInLeftDelay">
            {description}
          </span>
        </div>
        <div className="banner__image">
          <img src={image} alt="" className="banner__image__img" />
        </div>
        <div className="banner__logo fadeInRight">
          <img src={logo} alt="" className="banner__logo__img" />
          {/* <div className="banner__logo__container">
            <img src={logo} alt="" className="banner__logo__container__img" />
          </div> */}
        </div>
      </div>
    </MainContent>
  );
};
