// import { ConvencionesSst } from './pages/convenciones-sst/Convenciones-sst';
import './styles/main.scss';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Header } from './components/header/Header';
import { Main } from './pages/main/Main';
import { OutsourcingSst } from './pages/outsourcing-sst/Outsourcing-sst';
import { ProveedoresSst } from './pages/proveedores-sst/Proveedores-sst';
import { SerPymeSst } from './pages/ser-pyme-sst/Ser-pyme-sst';
import AboutUs from './pages/aboutUs/AboutUs';
import { Footer } from './components/footer/Footer';

export const App = () => {
  return (
    <div className="app">
      <Router>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/ser-pyme-sst" element={<SerPymeSst />} />
          <Route path="/outsourcing-sst" element={<OutsourcingSst />} />
          <Route path="/proveedores-sst" element={<ProveedoresSst />} />
          {/* <Route path="/convenciones-sst" element={<ConvencionesSst />} /> */}
          <Route path="/quienes-somos" element={<AboutUs />} />
        </Routes>
        <Footer></Footer>
      </Router>
    </div>
  );
};
