import { FaList } from 'react-icons/fa';
import { FaUniversity } from 'react-icons/fa';
import { FaCogs } from 'react-icons/fa';
import { FaGavel } from 'react-icons/fa6';
import { FaRegIdBadge } from 'react-icons/fa';
import { FaRegEye } from 'react-icons/fa';
import { FaExclamationTriangle } from 'react-icons/fa';
import { FaSearch } from 'react-icons/fa';
import { FaRegThumbsUp } from 'react-icons/fa6';
import { FaBook } from 'react-icons/fa';
import { BannerProps } from '@/src/shared/banner/Banner';
import { FaRegStar } from 'react-icons/fa6';
import { FaStarHalfAlt } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa6';
import { FaHeart } from 'react-icons/fa';
import { FaUsers } from 'react-icons/fa6';
import { FaCircleCheck } from 'react-icons/fa6';
import logo from 'src/assets/images/cheerful-professionals-using-technology-work.png';

export interface SerPymeSstItem {
  icon: JSX.Element;
  title: string;
  description: string;
}

export interface SerPymeSstPlan {
  quantity: string;
  type: string;
  label: string;
  prices: Record<'basic' | 'plus' | 'premium', number>;
}

export interface SerPymeSstProtection {
  icon: JSX.Element;
  title: string;
  description: string;
}

export const protections: SerPymeSstProtection[] = [
  {
    icon: <FaHeart />,
    title: 'Cumplimiento',
    description:
      'Te devolvemos hasta el 20% del valor contratado durante la vigencia del contrato, sus prórrogas y tres (3) meses más. Garantizando un cumplimiento de lo pactados en nuestros servicios.',
  },
  {
    icon: <FaUsers />,
    title: 'Responsabilidad por daño a terceros',
    description:
      'Se gestionan amparos para predios y operaciones mediante el diseño y administración del SG-SST, abordando riesgos relacionados con la seguridad y salud en el trabajo bajo responsabilidad civil extracontractual.',
  },
  {
    icon: <FaCircleCheck />,
    title: 'Calidad del servicio',
    description:
      'Nos comprometemos a ofrecer un servicio excepcional, respaldado por altos estándares y un enfoque centrado en satisfacer tus expectativas.',
  },
];

export const planIcons: Record<string, JSX.Element> = {
  basic: <FaRegStar />,
  plus: <FaStarHalfAlt />,
  premium: <FaStar />,
};

export const planCumpliance: Record<string, string> = {
  basic: 'Porcentaje de cumplimiento 61% a 72%',
  plus: 'Porcentaje de cumplimiento 73% a 86%',
  premium: 'Porcentaje de cumplimiento 87% a 100%',
};

export const plans: SerPymeSstPlan[] = [
  {
    quantity: '1 a 10',
    type: '1a10',
    label: 'Plan de 1 a 10 trabajadores',
    prices: {
      basic: 931640,
      plus: 1398827,
      premium: 1863281,
    },
  },
  {
    quantity: '11 a 50',
    type: '11a50',
    label: 'Plan de 11 a 50 trabajadores',
    prices: {
      basic: 1257713,
      plus: 1724901,
      premium: 2189355,
    },
  },
  {
    quantity: 'más de 51',
    type: 'mas51',
    label: 'Plan más de 51 trabajadores',
    prices: {
      basic: 1697913,
      plus: 2328617,
      premium: 2955630,
    },
  },
];

export const items: SerPymeSstItem[] = [
  {
    icon: <FaList />,
    title: 'Evaluación inicial del SG-SST',
    description:
      'Según la Resolución 0312 del 2019, que establece la evaluación de los estándares mínimos en seguridad y salud en el trabajo.',
  },
  {
    icon: <FaUniversity />,
    title: 'Formulación de políticas en SST',
    description:
      'Adaptaremos e implementaremos tus políticas para el compromiso de tu empresa y tus trabajadores en SST.',
  },
  {
    icon: <FaCogs />,
    title: 'Plan anual de trabajo',
    description:
      'Te estructuraremos el plan de actividades para la gestión del SG-SST.',
  },
  {
    icon: <FaGavel />,
    title: 'Matriz legal',
    description:
      'Estableceremos el marco legal que te aplica según tu actividad económica y procesos desarrollados.',
  },
  {
    icon: <FaRegIdBadge />,
    title: 'Matriz de identificación y evaluación de peligros',
    description:
      'De acuerdo a tus procesos desarrollados te apoyaremos en la identificación y evaluación de tus riesgos.',
  },
  {
    icon: <FaRegEye />,
    title: 'Programas de gestión',
    description:
      'De acuerdo a los procesos críticos que desarrolles te estableceremos que programas de gestión enfocados al riesgo se deben establecer.',
  },
  {
    icon: <FaExclamationTriangle />,
    title: 'Plan de prevención',
    description:
      'Preparación y respuesta de emergencias, según la ubicación geográfica, los procesos desarrollados estableceremos los preámbulos para el control de las amenazas y la prevención de emergencias.',
  },
  {
    icon: <FaSearch />,
    title: 'Investigación de accidentes de trabajo y enfermedades laborales',
    description:
      'Te guiaremos en el proceso legal que se debe cumplir para ATEL.',
  },
  {
    icon: <FaRegThumbsUp />,
    title: 'Plan de mejora',
    description:
      'Te entregaremos que prioridades se deben establecer para la disminución del riesgo legal.',
  },
  {
    icon: <FaBook />,
    title: 'Documentación del SG-SST, en las fases PHVA',
    description: 'PLANEAR, HACER, VERIFICAR Y ACTUAR',
  },
];

export const banner: BannerProps = {
  title: 'Ser Pyme SST',
  description: `aSERseguridad te brinda Respaldo garantizado para que te acredites en la implementación del SG-SST, bajo la modalidad de suscripción con un Precio cómodo mensual según el plan que elijas.

Conoce nuestro modelo de suscripción para la implementación del SG-SST.`,
  image:
    'https://images.unsplash.com/photo-1542744173-8e7e53415bb0?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
  logo,
};
