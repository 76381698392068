import { Link } from 'react-router-dom';
import { data, FooterSection } from './footer-data';
import './footer.scss';

export const Footer = () => {
  return (
    <div className="footer">
      <div className="footer__sections">
        {data.map((section: FooterSection, index: number) => (
          <div className={`footer__sections__section`} key={index}>
            <span className={`footer__sections__section__title`}>
              {section.title}
            </span>
            <div
              className={`footer__sections__section__items  ${section.items[0].socialIcon ? 'footer__sections__section__items__social' : ''}`}
            >
              {section.items.map((item, itemIndex) => (
                <Link
                  key={itemIndex}
                  to={item.url}
                  className={
                    item.socialIcon
                      ? `footer__sections__section__items__item-social footer__sections__section__items__item-social__${item.label}`
                      : 'footer__sections__section__items__item'
                  }
                >
                  {item.socialIcon ? (
                    item.socialIcon
                  ) : (
                    <>
                      {item.icon && (
                        <span className="footer__sections__section__items__item__icon">
                          {item.icon}
                        </span>
                      )}
                      <span className="footer__sections__section__items__item__label">
                        {item.label}
                      </span>
                    </>
                  )}
                </Link>
              ))}
            </div>
          </div>
        ))}
      </div>

      <div className="footer__separator">
        <div className="footer__separator__line"></div>
      </div>

      <div className="footer__bottom">
        <div className="footer__bottom__left">
          <img
            src="https://aserseguridad.co/assets/images/footer/favicon-aserseguridad.svg"
            alt="logo"
            className="footer__bottom__left__logo"
          />
          <span className="footer__bottom__left__label">
            Copyright © 2024 aSERseguridad DC SAS
          </span>
        </div>

        <div className="footer__bottom__right">
          <Link
            to={'http://www.grupogeos.co/politica-privacidad'}
            className="footer__bottom__right__link"
          >
            Política de Privacidad
          </Link>
          <div className="footer__bottom__right__separator">
            <div className="footer__bottom__right__separator__line"></div>
          </div>
          <Link
            to={'http://www.grupogeos.co/'}
            className="footer__bottom__right__link"
          >
            Empresa del Grupo Empresarial OS
          </Link>
          <img
            src="https://aserseguridad.co/assets/images/footer/logo-geos.svg"
            alt="logo"
            className="footer__bottom__right__logo"
          />
        </div>
      </div>
    </div>
  );
};
