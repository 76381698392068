import { MainContent } from 'src/shared/mainContent/MainContent';
import OurServices from 'src/pages/ourServices/OurServices';
import Home from '../home/Home';
import ContactUs from '../contactUs/ContactUs';
import './main.scss';
import CustomerCarousel from '../../components/customer-carousel/Customer-carousel';
import OfferYou from '../offerYou/OfferYou';

export const Main = () => {
  return (
    <div className="main-app">
      <MainContent>
        <Home></Home>
      </MainContent>

      <CustomerCarousel></CustomerCarousel>

      <OurServices />

      <OfferYou></OfferYou>

      <ContactUs></ContactUs>
    </div>
  );
};
