import './header-mobile-dropdown-button.scss';
import { FaChevronRight } from 'react-icons/fa6';
import { HeaderMobileButton } from '../header-mobile/Header-mobile';
import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';

export const HeaderMobileDropdownButton: React.FC<HeaderMobileButton> = ({
  icon,
  label,
  options,
  route,
  tabIndex,
  closeMenuMobile,
}) => {
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);

  const clickElement = (): void => {
    setIsActive(false);
    if (!options && route) {
      navigate(route);
      closeMenuMobile();
    } else setIsActive(!isActive);
  };

  return (
    <div
      className={`header-mobile-dropdown-button ${
        isActive ? 'header-mobile-dropdown-button__expanded' : ''
      }`}
    >
      <button
        className="header-mobile-dropdown-button__button"
        onClick={() => clickElement()}
        tabIndex={tabIndex}
      >
        <div className="header-mobile-dropdown-button__button__title">
          {React.cloneElement(icon, {
            className: 'header-mobile-dropdown-button__button__title__icon',
          })}
          <span className="header-mobile-dropdown-button__button__title__label">
            {label}
          </span>
        </div>

        {options && (
          <FaChevronRight className="header-mobile-dropdown-button__button__trigger" />
        )}
      </button>

      {options && (
        <div className={'header-mobile-dropdown-button__options'}>
          {options.map(
            (el: { label: string; route: string }, index: number) => (
              <Link
                key={`${el.label}-${index}`}
                className="header-mobile-dropdown-button__options__option"
                onClick={() => closeMenuMobile()}
                tabIndex={isActive ? 0 : -1}
                to={el.route}
              >
                {el.label}
              </Link>
            )
          )}
        </div>
      )}
    </div>
  );
};
