import './proveedores-sst.scss';
import { Banner, BannerProps } from '../../shared/banner/Banner';
import { TitleSection } from '../../shared/title-section/Title-section';
import {
  products,
  ProveedoresSstProduct,
  pastelColors,
} from './proveedores-sst-data';
import { useState } from 'react';
import { ProveedoresSstModal } from '../../../src/components/proveedores-sst-modal/Proveedores-sst-modal';
import { FaDownload } from 'react-icons/fa6';
import { LuChevronsLeft } from 'react-icons/lu';
import { LuChevronsRight } from 'react-icons/lu';
import logo from 'src/assets/images/close-up-people-working-from-home.png';
import imageLogo from 'src/assets/images/logo-proveedores-sst.png';

export const ProveedoresSst = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [cardInfo, setCardInfo] = useState({});

  const cardClick = (card: ProveedoresSstProduct) => {
    setCardInfo(card);
    setIsModalVisible(true);
  };

  const closeModal = () => setIsModalVisible(false);

  function openPDF() {
    window.open('/assets/pdf-plans/Brochure-aserseguridad.pdf', '_blank');
  }

  const banner: BannerProps = {
    title: 'Proveedores SST',
    description: `aSERseguridad te apoya en la Ejecución de tu sistema de gestión en seguridad en el trabajo, selecciona en nuestra gama de productos en SST, y dale cumplimiento a tu plan de trabajo.`,
    image:
      'https://images.unsplash.com/photo-1523240795612-9a054b0db644?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    logo,
  };
  return (
    <div className="proveedores-sst">
      <Banner
        description={banner.description}
        image={banner.image}
        logo={banner.logo}
        title={banner.title}
      ></Banner>

      <div className="proveedores-sst__content">
        <div className="our-products">
          <img className="our-products__logo" src={imageLogo} alt="logo"></img>

          <div className="our-products__content">
            <TitleSection
              title="Nuestros productos"
              align="left"
              description={`Contamos con expertos en seguridad y salud en el trabajo para ayudarte a gestionar y optimizar tu SG-SST.
Explora nuestras especialidades y conecta cada área con tu sistema.`}
            ></TitleSection>
            <div className="our-products__content__products">
              {products.map((el: ProveedoresSstProduct, index: number) => (
                <div
                  className="our-products__content__products__product"
                  key={index}
                  onClick={() => cardClick(el)}
                >
                  <div
                    className="our-products__content__products__product__icon"
                    style={{ color: pastelColors[index % pastelColors.length] }}
                  >
                    {el.icon}
                  </div>
                  <span className="our-products__content__products__product__label">
                    {el.title}
                  </span>
                  <div className="our-products__content__products__product__know-more">
                    <LuChevronsLeft />
                    <span className="our-products__content__products__product__know-more__label">
                      Conoce más
                    </span>
                    <LuChevronsRight />
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="our-products__download">
            <span className="our-products__download__label">
              Descarga nuestro portafolio completo
            </span>
            <button
              className="our-products__download__button"
              onClick={openPDF}
            >
              <span className="our-products__download__button__label">
                Descargar
              </span>
              <FaDownload />
            </button>
          </div>
        </div>
      </div>
      <ProveedoresSstModal
        isVisible={isModalVisible}
        onClose={closeModal}
        card={cardInfo as ProveedoresSstProduct}
      />
    </div>
  );
};
