import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';


const schema = yup.object().shape({
  nombres: yup.string().required('El campo nombres es obligatorio'),
  apellidos: yup.string().required('El campo apellidos es obligatorio'),
  email: yup
    .string()
    .email('Debe ser un correo válido')
    .required('El campo email es obligatorio'),
  celular: yup
    .string()
    .matches(/^[0-9]+$/, 'Debe ser un número válido')
    .min(10, 'Debe tener al menos 10 dígitos')
    .required('El campo celular es obligatorio'),
  empresa: yup.string().required('El nombre de la empresa es obligatorio'),
  tipoEmpresa: yup.string().required('Debe seleccionar el tipo de empresa'),
  opcionEmpresa: yup
    .string()
    .required('Debe seleccionar una opción para su empresa'),
  terminos: yup
    .boolean()
    .oneOf([true], 'Debe aceptar los términos y condiciones'),
});

const path =
  'https://script.google.com/macros/s/AKfycbwgGuHCHVvAyruDCTlt8ruVXb3LERWFYy54hc6mMrG0ECtnTLQnfj2RFUihLhRV-mke/exec';


const ContactForm = () => {
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm({
        resolver: yupResolver(schema),
      });
    
      const onSubmit = async (data: any) => {
        const payload = {
          nombre: data.nombres,
          apellido: data.apellidos,
          email: data.email,
          celular: data.celular,
          nombre_empresa: data.empresa,
          tipo_empresa: data.tipoEmpresa,
          opcion: String(data.terminos),
          plan: '',
        };
    
        console.log(payload);
    
        try {
          const response = await fetch(path, {
            method: 'POST',
            body: JSON.stringify(payload),
          });
    
          if (response.ok) {
            alert('Formulario enviado exitosamente');
          } else {
            alert('Error al enviar el formulario');
          }
        } catch (error) {
          console.error('Error:', error);
          alert('Error al enviar el formulario. Intente nuevamente.');
        }
      };
  return (
    <div className="flex items-center justify-center bg-gray-50 pb-6">
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="bg-white shadow-lg p-8 rounded-lg w-full max-w-lg"
    >
      <h2 className="text-xl font-semibold mb-4 text-center">
        Conoce más sobre nuestros planes
      </h2>

      {/* Campo: Nombres */}
      <div className="flex gap-2 max-md:flex-col">
        <div className="mb-4">
          <input
            type="text"
            placeholder="Nombres"
            {...register('nombres')}
            className="w-full border border-gray-300 p-2 rounded-md focus:ring focus:ring-orange-500"
          />
          <p className="text-red-500 text-sm mt-1">
            {errors.nombres?.message}
          </p>
        </div>

        {/* Campo: Apellidos */}
        <div className="mb-4">
          <input
            type="text"
            placeholder="Apellidos"
            {...register('apellidos')}
            className="w-full border border-gray-300 p-2 rounded-md focus:ring focus:ring-orange-500"
          />
          <p className="text-red-500 text-sm mt-1">
            {errors.apellidos?.message}
          </p>
        </div>
      </div>

      {/* Campo: Email */}
      <div className="mb-4">
        <input
          type="email"
          placeholder="Email"
          {...register('email')}
          className="w-full border border-gray-300 p-2 rounded-md focus:ring focus:ring-orange-500"
        />
        <p className="text-red-500 text-sm mt-1">
          {errors.email?.message}
        </p>
      </div>

      {/* Campo: Número de celular */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Número de celular"
          {...register('celular')}
          className="w-full border border-gray-300 p-2 rounded-md focus:ring focus:ring-orange-500"
        />
        <p className="text-red-500 text-sm mt-1">
          {errors.celular?.message}
        </p>
      </div>

      {/* Campo: Nombre de la empresa */}
      <div className="mb-4">
        <input
          type="text"
          placeholder="Nombre de la empresa"
          {...register('empresa')}
          className="w-full border border-gray-300 p-2 rounded-md focus:ring focus:ring-orange-500"
        />
        <p className="text-red-500 text-sm mt-1">
          {errors.empresa?.message}
        </p>
      </div>

      {/* Campo: Tipo de empresa */}
      <div className="mb-4">
        <select
          {...register('tipoEmpresa')}
          className="w-full border border-gray-300 p-2 rounded-md focus:ring focus:ring-orange-500"
        >
          <option value="">Seleccione el tipo de empresa</option>
          <option value="1-10 trabajadores">1-10 trabajadores</option>
          <option value="11-50 trabajadores">11-50 trabajadores</option>
          <option value="más de 50 trabajadores">
            Más de 50 trabajadores
          </option>
        </select>
        <p className="text-red-500 text-sm mt-1">
          {errors.tipoEmpresa?.message}
        </p>
      </div>

      {/* Campo: Mejor opción para su empresa */}
      <div className="mb-4">
        <select
          {...register('opcionEmpresa')}
          className="w-full border border-gray-300 p-2 rounded-md focus:ring focus:ring-orange-500"
        >
          <option value="">Seleccione una opción</option>
          <option value="Planes de suscripción al SG-SST">
            Planes de suscripción al SG-SST
          </option>
          <option value="Acompañamiento outsourcing SST">
            Acompañamiento outsourcing SST
          </option>
          <option value="Actividades proveedor SST">
            Actividades proveedor SST
          </option>
        </select>
        <p className="text-red-500 text-sm mt-1">
          {errors.opcionEmpresa?.message}
        </p>
      </div>

      {/* Checkbox: Acepto términos */}
      <div className="mb-6">
        <label className="inline-flex items-center">
          <input
            type="checkbox"
            {...register('terminos')}
            className="w-4 h-4 text-orange-500 border-gray-300 rounded focus:ring focus:ring-orange-500"
          />
          <span className="ml-2 text-sm">
            Acepto los{' '}
            <a
              href="/terminos-y-condiciones"
              className="text-orange-500 underline"
            >
              términos y condiciones
            </a>
          </span>
        </label>
        <p className="text-red-500 text-sm mt-1">
          {errors.terminos?.message}
        </p>
      </div>

      {/* Botón Enviar */}
      <button
        type="submit"
        className="bg-orange-500 text-white w-full p-2 rounded-md hover:bg-orange-600"
      >
        Enviar formulario
      </button>
    </form>
  </div>
  )
}

export default ContactForm;
